import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import favicon from '../favicon.png'
import '../global.css'

import Nav from '../navigation.js'
import Footer from '../footer.js'

import jobsBg from '../jobsBg.jpg'

import jobFront from '../jobFront.jpg'
import jobMobile from '../jobMobile.jpg'
import jobJava from '../jobJava.jpg'
import jobData from '../jobData.jpg'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0 50px;
    text-align: center;
  }
`

const Hero = styled.header`
  text-align: center;
  position: relative;
  height: 50%;
  background-image: url(${jobsBg});
  background-size: cover;
  background-position: top;
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    svg {
      width: 90%;
      max-width: 100px;
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
  }
`

const Description = styled.section`
  text-align: center;
  font-size: 1.1em;
  padding: 40px 0;
  p {
    width: 80%;
    max-width: 790px;
    margin: 0 auto;
    padding: 15px 30px;
    font-family: nunitoReg, sans-serif;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`

const Jobs = styled.div`
  padding-bottom: 50px;
  .jobGrid {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
  }
  .letter {
    font-family: nunitoReg, sans-serif;
    font-size: 4em;
  }
  a {
    flex: 0 50%;
    height: 450px;
    text-align: center;
    color: white;
    position: relative;
    background: #1f2832;
    overflow: hidden;
    transition: background 350ms ease-in-out, background 350ms ease-in-out;
    @media (max-width: 800px) {
      flex: 0 100%;
    }
    &:hover {
      background: #3789d2;
      cursor: pointer;
      .letter {
        font-size: 6em;
      }
      img {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        opacity: 0.2;
        transform: scale(1.3);
      }
    }
    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.1;
      transform: scale(1.1);
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      transition: filter 350ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 450ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
        transform 850ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    span {
      display: inline-block;
      width: 100%;
      font-size: 1.6em;
      font-family: nunitoSemi, sans-serif;
    }
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>

    <Nav />
    <Hero>
      <div className="wrapper">
        <svg viewBox="0 0 113 109" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.7978 99.8008C51.4486 99.9036 51.0994 100.006 50.7475 100.102C48.7704 100.621 46.7924 100.637 44.825 100.08C41.4321 99.1165 38.7706 97.1618 36.8995 94.161C36.1959 93.0333 35.6753 91.8261 35.2975 90.5543C34.8516 89.062 34.6235 87.5399 34.5822 85.9828C34.5142 83.4996 34.9005 81.0873 35.6354 78.7181C36.193 76.9336 36.9385 75.2302 37.8478 73.5999C38.6997 72.0738 39.3962 70.4757 39.9975 68.8332C40.7509 66.7649 41.341 64.6483 41.9175 62.5284C42.4243 60.6502 42.9101 58.7714 43.498 56.9217C43.941 55.5286 44.4414 54.156 45.078 52.8375C45.8769 51.1894 46.8676 49.679 48.2343 48.4346C49.2578 47.503 50.4159 46.7799 51.7088 46.278C53.1679 45.7069 54.6766 45.3173 56.2362 45.1462C58.6272 44.8831 60.913 45.2533 63.1168 46.2067C64.6261 46.8651 66.0127 47.7222 67.3547 48.6622C68.7763 49.6604 70.1418 50.7331 71.5279 51.7818C73.0906 52.9613 74.6732 54.1045 76.3512 55.1213C78.0191 56.1333 79.7691 56.9949 81.59 57.6974C83.7945 58.5506 85.8711 59.6338 87.8012 60.9992C89.449 62.1726 90.9363 63.521 92.2078 65.0983C93.1973 66.3252 94.024 67.6533 94.6395 69.1086C95.5807 71.3181 95.9795 73.6139 95.6966 76.0133C95.4371 78.2378 94.6202 80.2461 93.337 82.0713C92.3933 83.4147 91.2765 84.5801 89.9093 85.5044C89.4256 85.8311 88.9213 86.1237 88.3947 86.3786C88.3203 86.4142 88.2523 86.4557 88.183 86.4935C88.128 86.5594 88.0366 86.5595 87.9659 86.5937C87.9062 86.6238 87.8308 86.6223 87.7855 86.6805L86.3878 87.1917C86.318 87.1715 86.2664 87.2236 86.205 87.2377L86.0436 87.2968L85.9078 87.3464C84.7021 87.7458 83.4516 87.9203 82.198 88.0752C80.0727 88.3368 77.9339 88.4369 75.8085 88.6528C74.0847 88.8298 72.3768 89.0843 70.6918 89.4926C69.5223 89.7748 68.3943 90.1707 67.2865 90.6216C66.4021 90.9783 65.5617 91.4438 64.7378 91.9322C63.1147 92.8961 61.5805 93.9896 60.045 95.0794C58.4212 96.2348 56.7937 97.3916 55.0548 98.3686C54.347 98.7648 53.6325 99.1425 52.8631 99.4156C52.704 99.4697 52.5593 99.5517 52.3966 99.6071C52.2018 99.6658 52.0157 99.7713 51.7979 99.8011L51.7978 99.8008Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.9785 9.74087C60.6052 9.12126 62.9958 9.69852 65.2012 11.1795C66.7122 12.195 67.9144 13.5146 68.9135 15.0292C69.7692 16.3342 70.429 17.74 70.918 19.2249C71.8161 21.9535 72.118 24.7463 71.836 27.6029C71.6411 29.5833 71.1496 31.4808 70.2934 33.2789C69.5301 34.8766 68.5282 36.2995 67.17 37.4534C66.2183 38.2631 65.1586 38.8795 63.9591 39.2392C61.8838 39.8651 59.8644 39.6886 57.895 38.8075C56.2186 38.0565 54.8422 36.9086 53.6699 35.5114C52.8508 34.5341 52.1723 33.4637 51.6018 32.3248C51.2122 31.5439 50.8777 30.7428 50.6055 29.919C49.9598 27.9715 49.624 25.9731 49.5822 23.9253C49.5378 21.7455 49.8271 19.6141 50.5275 17.5486C51.1186 15.81 51.9647 14.2112 53.1739 12.8207C54.0638 11.7965 55.1024 10.9632 56.3195 10.3559C56.3481 10.3204 56.3961 10.3153 56.4352 10.2969L56.5562 10.2526C56.6122 10.2238 56.6853 10.2304 56.7206 10.1676L57.546 9.86566C57.6172 9.88956 57.6651 9.83875 57.7265 9.82462L57.8476 9.78034C57.8926 9.75556 57.9289 9.72979 57.9787 9.74071L57.9785 9.74087Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9574 60.6329C10.7173 59.8514 10.5766 59.046 10.5073 58.2311C10.3689 56.6471 10.5306 55.099 11.1128 53.6092C11.7692 51.9466 12.847 50.6291 14.3747 49.7001C15.6172 48.9462 16.9794 48.5769 18.4243 48.5018C20.0865 48.418 21.693 48.7163 23.2413 49.3106C25.6429 50.2249 27.6637 51.6902 29.3806 53.587C30.2806 54.5805 31.0485 55.668 31.6868 56.8446C31.9962 57.4178 32.2617 58.007 32.4967 58.6156C32.9427 59.7668 33.2426 60.9507 33.3657 62.1742C33.5316 63.8106 33.3837 65.4078 32.7795 66.9514C32.088 68.7225 30.9201 70.0896 29.2602 71.0212C28.0417 71.7039 26.7177 72.0301 25.3264 72.0814C23.455 72.1503 21.6748 71.7408 19.9714 70.9953C18.308 70.2685 16.8294 69.2621 15.5108 68.0224C14.2177 66.8067 13.1261 65.4257 12.2729 63.8661C12.1215 63.5887 11.9788 63.3123 11.8348 63.0322C11.7572 62.9109 11.7185 62.7711 11.6359 62.6475C11.6011 62.5978 11.5896 62.5438 11.5645 62.4864C11.534 62.4144 11.5402 62.3289 11.4706 62.2753L11.0789 61.2041C11.1087 61.0809 11.0367 60.9866 11.0052 60.8775C10.9843 60.7978 10.9315 60.7215 10.9573 60.633L10.9574 60.6329Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.3479 36.9973C21.5784 34.8705 21.1811 32.8405 21.2179 30.7349C21.2437 29.145 21.5141 27.5986 22.1365 26.1274C22.8074 24.5386 23.8094 23.2071 25.2443 22.2289C26.6779 21.2471 28.2762 20.7956 30.0074 20.7863C31.5322 20.7818 32.9879 21.1228 34.389 21.7126C36.6207 22.6516 38.4974 24.0865 40.1159 25.8737C41.1436 27.0119 42.021 28.2507 42.7507 29.5976C43.1669 30.3604 43.5331 31.1457 43.847 31.9584C44.2424 32.9826 44.5507 34.0303 44.7536 35.1082C45.1783 37.3611 45.1788 39.6028 44.5348 41.8223C44.1322 43.2007 43.4911 44.4501 42.535 45.5318C41.4676 46.7416 40.1542 47.563 38.6035 47.9971C37.2049 48.3921 35.7828 48.4048 34.359 48.1395C32.4978 47.7929 30.8147 47.0234 29.2591 45.966C27.4135 44.7069 25.8838 43.1285 24.6099 41.2943C23.9148 40.2924 23.3145 39.2309 22.8115 38.1171C22.6215 37.7 22.4338 37.2778 22.3478 36.9973L22.3479 36.9973Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.8556 30.9921L95.9455 31.2379C96.6001 33.3235 96.7579 35.4495 96.5437 37.6158C96.374 39.3125 95.9665 40.9505 95.2926 42.5197C94.4276 44.5331 93.2144 46.2993 91.5227 47.7125C90.509 48.5574 89.3822 49.2066 88.113 49.5918C86.2412 50.1599 84.3989 50.0809 82.5912 49.3237C81.1012 48.6957 79.9033 47.6948 78.9164 46.4294C78.0323 45.297 77.3805 44.038 76.9284 42.6769C75.9374 39.6829 75.8881 36.6479 76.6092 33.5933C76.8513 32.5606 77.2005 31.5594 77.6473 30.5975C78.5691 28.6257 79.8207 26.9076 81.5489 25.5602C82.534 24.7965 83.6159 24.2178 84.816 23.8828C86.6499 23.3702 88.456 23.4749 90.2078 24.2276C91.792 24.9085 93.0385 26.0081 94.0496 27.3853C94.6103 28.1452 95.0714 28.9623 95.4254 29.8393L95.5167 30.0888C95.6267 30.3896 95.7367 30.6905 95.8554 30.9923L95.8556 30.9921Z"
            fill="white"
          />
        </svg>
        <h1>Working For Splendo</h1>
      </div>
    </Hero>

    <Description>
      <p>
        Splendo is a technical oriented company with an open culture. We’re looking for people that want to stay current with the latest technologies
        in order to come up with creative solutions we use in-house and together with our customers.
      </p>

      <p>
        Our goal is creating long term and maintainable projects. We do this by working with as little overhead as possible (no project managers!),
        close contact with any customers, and a team of people that know how to autonomously implement the best solutions across the technology stack.
        The result should be the best experiences for our end-users.
      </p>

      <p>
        This is what lets us do some of the more interesting and complex jobs out there. At above all, we have Frits our company dog, that you can
        take for a walk! If this seems like something you’d want to be part of, come by for a coffee and we'll talk about the possibilities.
      </p>
    </Description>

    <Jobs>
      <h1 className="sectionHeader">Current Openings</h1>
      <div className="jobGrid">
        <Link to="/jobs/mobile">
          <div className="job">
            <img src={jobMobile} alt="Mobile Developer" />
            <div className="wrapper">
              <span className="letter">M</span>
              <span>Mobile Developer</span>
            </div>
          </div>
        </Link>

        <Link to="/jobs/computer-vision-engineer">
          <div className="job">
            <img src={jobFront} alt="Computer Vision Engineer" />
            <div className="wrapper">
              <span className="letter">C</span>
              <span>Computer Vision Engineer</span>
            </div>
          </div>
        </Link>

        <Link to="/jobs/java">
          <div className="job">
            <img src={jobJava} alt="Java Developer" />
            <div className="wrapper">
              <span className="letter">J</span>
              <span>Java Developer</span>
            </div>
          </div>
        </Link>

        <Link to="/jobs/data">
          <div className="job">
            <img src={jobData} alt="Data Developer" />
            <div className="wrapper">
              <span className="letter">D</span>
              <span>Machine Learning Developer</span>
            </div>
          </div>
        </Link>
      </div>
    </Jobs>

    <Footer />
  </Container>
)
